import { StyleSheet } from "react-native";

export const Styles = StyleSheet.create({
  container: {
    justifyContent: 'center', 
    alignItems: 'center'
  },

  passive: { },
  active: { }
});
